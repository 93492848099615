/* eslint-disable import/no-default-export */
import {controllerFactory} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/controller-factory/controllerFactory';
import {GalleryController} from '../../viewerScript/GalleryController';

export interface GalleryControllerExtraParams {
  isUsingExternalDataSource: boolean;
}

export default controllerFactory<GalleryController, GalleryControllerExtraParams>(GalleryController, {
  shouldSkipMultilingual: true,
  extraParams: {
    isUsingExternalDataSource: true,
  },
});
